<script>
import { mapActions } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'

export default {
  name: 'ManagementWebhook',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    FilterList: () => import('@/components/general/FilterList'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      showTokenModal: false,
      generatedToken: '',

      webhook: {
        id: -1,
        name: ''
      },

      tokens: []
    }
  },

  computed: {
    canWrite () {
      return this.getContextPermission('webhooks') === 'write'
    }
  },

  created () {
    this.attemptGetWebhook(this.$route.params.id)
      .then(({ data }) => {
        this.webhook = data
      })

    this.attemptListWebhookTokens(this.$route.params.id)
      .then(({ data }) => {
        this.tokens = data.data
      })
  },

  methods: {
    ...mapActions([
      'setFeedback',
      'attemptGetWebhook',
      'attemptCreateWebhookToken',
      'attemptListWebhookTokens',
      'attemptRevokeWebhookToken'
    ]),

    leave () {
      this.$router.push({ name: 'management.webhooks' })
    },

    createNewToken () {
      this.attemptCreateWebhookToken(Number(this.$route.params.id))
        .then(({ data }) => {
          this.generatedToken = data
          this.showTokenModal = true

          this.attemptListWebhookTokens(Number(this.$route.params.id))
            .then(({ data }) => {
              this.tokens = data.data
            })
        })
    },

    revokeToken (id) {
      this.attemptRevokeWebhookToken(id)
        .then(() => {
          this.attemptListWebhookTokens(this.$route.params.id)
            .then(({ data }) => {
              this.tokens = data.data
            })
        })
    }
  }
}
</script>

<template>
  <div class="main-content management-create">
    <ContentHeader
      title="Webhook"
      dark-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        text="Voltar para Webhooks"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>

    <div class="main-content-inner-big center-small">
      <div class="management-create">
        <div class="management-create-header">
          <p class="management-create-description">
            <strong>Webhook</strong> - {{ webhook.name }}
          </p>
        </div>

        <FilterList>
          <Action
            slot="button"
            type="button"
            primary
            large
            fixed-width
            text="Gerar novo token"
            :disabled="!canWrite"
            @click="createNewToken"
          />
        </FilterList>

        <template v-if="tokens.length">
          <Datatable :items="tokens">
            <template slot="thead">
              <tr>
                <th class="th">
                  Token
                </th>
                <th
                  class="th"
                  width="40"
                />
              </tr>
            </template>
            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td
                  class="td"
                  :disabled="props.item.status !== 'active'"
                >
                  <span class="td-text bolder">{{ props.item.token }}</span>
                </td>
                <td
                  class="td td-actions"
                  width="40"
                >
                  <Dropdown
                    icon="dots-vertical"
                    right
                    :disabled="props.item.status !== 'active' || !canWrite"
                  >
                    <DropdownLink
                      text="Revogar"
                      @click="revokeToken(props.item.id)"
                    />
                  </Dropdown>
                </td>
              </tr>
            </template>
          </Datatable>
        </template>
        <template v-else>
          <p class="revoke-token-message">
            Nenhum token gerado ou ativo
          </p>
        </template>
      </div>
    </div>

    <ModalConfirm
      :active="showTokenModal"
      title="Token Gerado"
      hide-cancel
      @confirmAction="showTokenModal = false"
    >
      <div class="token-modal">
        <p>Token gerado com sucesso. Guarde-o em um local seguro, pois não será possível visualizá-lo novamente.</p>
        <p>Caso perca o token, será necessário gerar um novo.</p>
        <br>
        <p>Token: <br><strong class="small-text">{{ generatedToken }}</strong></p>
      </div>
    </ModalConfirm>
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>

<style scoped>
td[disabled] {
  opacity: 0.5;
}

.revoke-token-message {
  margin-top: 20px;
  font-size: 20px;
  color: #666;
  text-align: center;
}

.token-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.token-modal p {
  padding-inline: 12px;
}
.token-modal .small-text {
  font-size: 14px;
}
</style>
